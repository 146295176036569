import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Box, Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Link from "@mui/material/Link";

const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  ...theme.typography.body2,
  textAlign: "left",
  backgroundColor: theme.palette.primary.light,
}));

export default function Contacts() {
  const theme = useTheme();
  const defaultState = {
    center: [56.139953, 40.415932],
    zoom: 15,
  };

  return (
    <Box sx={{}}>
      <YMaps>
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            display: { xs: "flex", md: "flex", lg: "flex" },
          }}
        >
          <DemoPaper
            variant="outlined"
            sx={{
              color: theme.palette.secondary.main,
              mt: { xs: 1, md: 10, lg: 20 },
              ml: { xs: 1, md: 10, lg: 30 },
            }}
          >
            <Typography fontWeight={"bold"} fontSize={{ xs: 30, md: 60, lg: 70 }} variant="h4">
              Контакты
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Typography fontSize={{ xs: 17, md: 25, lg: 25 }} variant="subtitle1">
              г Калуга, Московская ул, д. 340, офис 3
              </Typography>
              <br />
              <Typography fontSize={{ xs: 17, md: 25, lg: 25 }} variant="subtitle1">
                +7 (926) 866-27-50
              </Typography>
              <Typography fontSize={{ xs: 17, md: 25, lg: 25 }} variant="subtitle1">
              support@stairs-artemida.ru
              </Typography>
              <br />
              <Button
                variant="contained"
                color="success"
                href="https://wa.me/79268662750?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%21%20%D0%9C%D0%BD%D0%B5%20%D0%BD%D0%B5%D0%BE%D0%B1%D1%85%D0%BE%D0%B4%D0%B8%D0%BC%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F."
                endIcon={<WhatsAppIcon fontSize="large" color="white" />}
              >
                WhatsApp
              </Button>
            </Box>
          </DemoPaper>
        </Box>
        <Map width="100%" height="70vh" defaultState={defaultState}>
          <Placemark geometry={[56.139953, 40.415932]} />
        </Map>
      </YMaps>
    </Box>
  );
}
