import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import header from "../img/header.png";
import logo from "../img/artemida_logo.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Button, Container, Grid, Stack, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import MainModal from "./modal/MainModal";
import { ToastContainer } from "react-toastify";

export default function MenuAppBar() {
  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#8e8567"),
    backgroundColor: "#8e8567",
    "&:hover": {
      backgroundColor: "#5f3d02",
    },
  }));

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundImage: `url(${header})`,
        maxHeight: "100%",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#bdd1cc",
        color: "white",
        pb: 0.2,
      }}
    >
      <MainModal active={active} setActive={setActive} />
      <Box>
        <Box
          sx={{
            backgroundColor: "rgba(0,0,0,0.3)",
            display: { xs: "flex", md: "flex", lg: "none" },
          }}
        >
          <Grid container sx={{ m: 2 }}>
            <Grid xs={12}>
              <img src={logo} style={{ maxWidth: "100%", height: "auto" }} />
            </Grid>
            <Grid xs={12}>
              <Typography
                variant="h1/2"
                component="div"
                sx={{ maxWidth: "100%" }}
              >
                Производим лестницы из дерева, металлические каркасы и обшиваем
                бетон "под ключ" больше 10 лет в Москве и области
              </Typography>
            </Grid>
            <Grid xs={6} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                color="success"
                href="https://wa.me/79268662750?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%21%20%D0%9C%D0%BD%D0%B5%20%D0%BD%D0%B5%D0%BE%D0%B1%D1%85%D0%BE%D0%B4%D0%B8%D0%BC%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F."
                endIcon={<WhatsAppIcon fontSize="large" color="white" />}
              >
                WhatsApp
              </Button>
            </Grid>
            <Grid xs={6} sx={{ mt: 1 }}>
              <Typography variant="h6" component="div">
                +7 (926) 866-27-50
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none", lg: "none" } }}>
          <Grid container spacing={2} sx={{ textAlign: "center", my: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                component="div"
                sx={{ maxWidth: "100%", fontWeight: "bold" }}
              >
                ВЫБЕРИТЕ ЛЕСТНИЦУ ДЛЯ СВОЕГО ДОМА
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography
                variant="inherit"
                component="div"
                sx={{ maxWidth: "100%", fontSize: 25 }}
              >
                Сделаем эскизный проект лестницы в 3х вариантах с 3D
                визуализацией и расчетом сметы уже на следующий день после
                замера в специализированной программе STAIRCON
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <ColorButton
                variant="contained"
                size="large"
                sx={{ maxWidth: 500, height: 100, fontSize: 25 }}
                onClick={handleOpen}
              >
                Пригласить на замер
              </ColorButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex", lg: "none" } }}>
          <Grid container spacing={2} sx={{ textAlign: "center", my: 20 }}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                component="div"
                sx={{ maxWidth: "100%", fontWeight: "bold" }}
              >
                ВЫБЕРИТЕ ЛЕСТНИЦУ ДЛЯ СВОЕГО ДОМА
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography
                variant="inherit"
                component="div"
                sx={{ maxWidth: "100%", fontSize: 40 }}
              >
                Сделаем эскизный проект лестницы в 3х вариантах с 3D
                визуализацией и расчетом сметы уже на следующий день после
                замера в специализированной программе STAIRCON
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <ColorButton
                variant="contained"
                size="large"
                sx={{ maxWidth: 600, height: 200, fontSize: 40 }}
                onClick={handleOpen}
              >
                Пригласить на замер
              </ColorButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <AppBar
        position="static"
        sx={{
          backgroundColor: "rgba(0,0,0,0.3)",
          display: { xs: "none", md: "none", lg: "flex" },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={logo} style={{ maxWidth: "100%", height: "auto" }} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 9 }}
          ></Typography>
          <Typography variant="h1/2" component="div" sx={{ maxWidth: 300 }}>
            Производим лестницы из дерева, металлические каркасы и обшиваем
            бетон "под ключ" больше 10 лет в Москве и области
          </Typography>
          <Button
            variant="contained"
            color="success"
            href="https://wa.me/79268662750?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%21%20%D0%9C%D0%BD%D0%B5%20%D0%BD%D0%B5%D0%BE%D0%B1%D1%85%D0%BE%D0%B4%D0%B8%D0%BC%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F."
            endIcon={<WhatsAppIcon fontSize="large" color="white" />}
          >
            WhatsApp
          </Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 0, ml: 1 }}>
            +7 (926) 866-27-50
          </Typography>
        </Toolbar>
      </AppBar>
      <Container fixed>
        <Box sx={{ my: 20, display: { xs: "none", md: "none", lg: "flex" } }}>
          <Stack spacing={5}>
            <Typography
              variant="h1"
              component="div"
              sx={{ maxWidth: "100%", fontWeight: "bold" }}
            >
              ВЫБЕРИТЕ ЛЕСТНИЦУ ДЛЯ СВОЕГО ДОМА
            </Typography>
            <Typography
              variant="inherit"
              component="div"
              sx={{ maxWidth: "100%", fontSize: 30 }}
            >
              Сделаем эскизный проект лестницы в 3х вариантах с 3D визуализацией
              и расчетом сметы уже на следующий день после замера в
              специализированной программе STAIRCON
            </Typography>
            <ColorButton
              variant="contained"
              size="large"
              sx={{ maxWidth: 500, height: 100, fontSize: 25 }}
              onClick={handleOpen}
            >
              Пригласить на замер
            </ColorButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
